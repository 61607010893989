<template>
  <div>
    <AppLabel label="ACCOMODATION" :label-right="formatDate" />
    <HotelCardDetails
      v-if="showAlt"
      :hotel="hotel"
      :room="room"
      :price="infoPrice"
      :show-card="showCard"
      :show-details="false"
      @click="$emit('click')"
    />
    <HotelCardSummary
      v-else
      :hotel="hotel"
      :room="room"
      :show-info="showInfo"
      :info-label="infoLabel"
      :info-price="infoPrice"
      :show-card="showCard"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
import { date } from '../../../other/utils/helpers/date'
import AppLabel from '../../AppLabel.vue'
import HotelCardDetails from '../hotels/HotelCardDetails.vue'
import HotelCardSummary from '../hotels/HotelCardSummary.vue'

const formatDate = timestamp => date.formatUTC('-d -ms, -ds', timestamp)

export default {
  name: 'SummaryHotel',
  props: {
    hotel: Object,
    room: Object,
    infoLabel: String,
    infoPrice: Number,
    showAlt: Boolean,
    showCard: Boolean,
    showInfo: Boolean,
  },
  components: { AppLabel, HotelCardDetails, HotelCardSummary },
  computed: {
    formatDate() {
      const checkIn = this.hotel.checkInDatetime
      const checkOut = this.hotel.checkOutDatetime

      return `${formatDate(checkIn)} - ${formatDate(checkOut)}`
    },
  },
}
</script>

<style scoped lang="scss"></style>
