<template>
  <div>
    <div class="hotel-card-header-content">
      <p style="font-family: LuciferSans; font-size: 1rem">{{ hotel.name }}</p>
      <p style="color: var(--color-primary); margin-top: 0.25rem">
        {{ hotel.rating }} Star Hotel
      </p>
      <p>{{ hotel.location.display.city }}</p>
    </div>
    <div
      :class="{ 'app-card-clickable': showCard }"
      class="hotel-card"
      tabindex="0"
      @click="$emit('click')"
      @keyup.enter="$emit('click')"
    >
      <div class="hotel-card-image-wrapper">
        <img
          v-if="!imageLoadFailed && hotel.mediaList[0]"
          :src="hotel.mediaList[0]"
          height="250"
          style="width: 100%"
          class="hotel-card-image"
          alt="hotel"
          @error="imageLoadFailed = true"
        />
        <div
          v-if="imageLoadFailed || !hotel.mediaList[0]"
          style="
            padding: 2rem 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--color-icon-background);
          "
        >
          <IconPlaceholderHotelHorizontal style="color: var(--color-icon)" />
        </div>
      </div>
      <AppCardInfo
        v-if="showInfo"
        style="padding: 1rem"
        :label="infoLabel"
        :price="infoPrice"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { date } from '../../../other/utils/helpers/date'
import AppCardInfo from '../../AppCardInfo.vue'
import IconPlaceholderHotelHorizontal from '../../icons/placeholders/IconPlaceholderHotelHorizontal.vue'

export default {
  name: 'HotelCardSummary',
  components: { AppCardInfo, IconPlaceholderHotelHorizontal },
  props: {
    hotel: Object,
    showCard: Boolean,
    showInfo: Boolean,
    infoPrice: Number,
    infoLabel: String,
  },
  data() {
    return { imageLoadFailed: false }
  },
  computed: {
    ...mapGetters('SearchState', ['passengerText']),
    checkIn() {
      const checkIn = this.hotel.checkInDatetime
      const parsedDate = date.format('-d -ml', checkIn)
      const parsedTime = date.format('-h:-min2', checkIn, true)
      return `${parsedDate} · Check In · ${parsedTime}`
    },
    image: vm => vm.hotel.mediaList[0],
  },
}
</script>

<style scoped lang="scss">
.hotel-card {
  margin-top: 0.5rem;
  padding: 0;
}
@media (hover: hover) {
  .hotel-card:hover {
    .hotel-card-image {
      transform: scale(1.01);
    }
    .app-card-info {
      --box-shadow-height: 2px;
    }
  }
}
.hotel-card-image {
  transition: transform 0.5s ease-in-out;
}
.hotel-card-image-wrapper {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;
  clip-path: content-box;
}
.hotel-card-header-content {
  display: grid;
  font-size: 13px;
  font-family: DDin;
}
</style>
