<template>
  <div
    :class="{ 'app-card-clickable': showCard }"
    tabindex="0"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <div class="hotel-card-header">
      <AppThumbnailWithPlaceholder
        :src="hotel.mediaList[0]"
        placeholder="hotel"
      />
      <div class="hotel-card-header-content">
        <p style="font-family: LuciferSans">{{ hotel.name }}</p>
        <p style="color: var(--color-primary); margin-top: 0.25rem">
          {{ hotel.rating }} Star Hotel
        </p>
        <p>{{ hotel.location.display.city }}</p>
        <!-- <p>{{ room.name }}</p> -->
      </div>
    </div>
    <div v-if="showInfo" class="hotel-card-content">
      <div class="hotel-card-amenities">
        <template v-for="amenity in hotel.amenitiesList.slice(0, 2)">
          <img :src="amenity.imageUrl" width="26" height="26" alt="amenity" />
          <p>{{ amenity.name }}</p>
        </template>
        <IconCalendar style="color: #8f8f8f" />
        <p>{{ checkIn }}</p>
        <IconPassengers style="color: #8f8f8f" />
        <p>
          {{ [passengerText.total, ...passengerText.passengers].join(' · ') }}
        </p>
      </div>
      <p class="hotel-card-price">
        {{ $formatPrice(infoPrice) }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { date } from '../../../other/utils/helpers/date'
import AppThumbnailWithPlaceholder from '../../AppThumbnailWithPlaceholder.vue'
import IconCalendar from '../../icons/common/IconCalendar.vue'
import IconPassengers from '../../icons/common/IconPassengers.vue'

const formatDate = timestamp => date.formatUTC('-d -ml', timestamp)
const formatTime = timestamp => date.formatUTC('-h:-min2', timestamp, true)

export default {
  name: 'HotelCardDetails',
  props: {
    hotel: Object,
    room: Object,
    infoPrice: Number,
    showInfo: Boolean,
    showCard: Boolean,
  },
  components: {
    IconPassengers,
    IconCalendar,
    AppThumbnailWithPlaceholder,
  },
  computed: {
    ...mapGetters('SearchState', ['passengerText']),
    checkIn() {
      const checkIn = this.hotel.checkInDatetime
      const parsedDate = formatDate(checkIn)
      const parsedTime = formatTime(checkIn)
      return `${parsedDate} · Check In · ${parsedTime}`
    },
    image: vm => vm.hotel.mediaList[0],
  },
}
</script>

<style scoped lang="scss">
.hotel-card-header {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
}
.hotel-card-header-content {
  display: grid;
  font-size: 13px;
  font-family: DDin;
}
.hotel-card-content {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  margin-top: 1rem;
}
.hotel-card-amenities {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  row-gap: 0.75rem;
  align-items: center;
  font-family: DDin;
  font-size: 13px;
  & > *:nth-child(2n-1) {
    justify-self: center;
  }
}
.hotel-card-price {
  align-self: flex-end;
  font-size: 16px;
  font-family: SpaceMono;
}
</style>
