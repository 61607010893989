<template>
  <div>
    <AppLabel :label="label" :label-right="displayDepartureDate" />
    <FlightCardSummary
      :flight-direction="flightDirection"
      :show-card="showCard"
      :show-info="showInfo"
      :info-label="infoLabel"
      :info-price="infoPrice"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
import { date } from '../../../other/utils/helpers/date'
import AppLabel from '../../AppLabel.vue'
import FlightCardSummary from '../flights/FlightCardSummary.vue'

const formatDepartureDate = timestamp =>
  date.formatUTC('-d -ms, -dl', timestamp)

export default {
  name: 'SummaryFlight',
  components: { AppLabel, FlightCardSummary },
  props: {
    label: String,
    flightDirection: Object,
    infoPrice: Number,
    infoLabel: String,
    showInfo: Boolean,
    showCard: Boolean,
  },
  computed: {
    displayDepartureDate() {
      const date = this.flightDirection.segments[0].departureDatetime
      return formatDepartureDate(date)
    },
  },
}
</script>

<style scoped lang="scss"></style>
